<template lang="pug">
.org-type-create-edit-dialog 
  CreateEditDialog(
    title="Add Organization Type"
    :item="orgType"
    :isOpen="isOpen"
    @close="$emit('close')"
    @submit-form="saveOrgType"
    :saveAction="editingOrgType ? 'Save' : 'Add'"
  )
    template( 
      v-slot:form="{ clone, rules }"
    )
      v-text-field(
        outlined
        dense
        label="Name"
        v-model="clone.name"
        :rules="[rules.required]"
      )

      v-select(
        :loading="isPending"
        outlined
        dense
        label="IRS Designation"
        :items="irsDesignations"
        v-model="clone.irsDesignationId",
        :rules="[rules.required]"
        item-text="name"
        item-value="id"
      )

      AddressForm(
        :clone="clone"
        :rules="rules"
        addressKey="address"
      )
</template>

<script>
import AddressForm from '@/components/Address.Form'
import { useFind } from 'feathers-vuex'
import CreateEditDialog from '@/components/Dialogs/CreateEdit.Dialog'

export default {
  name: 'OrgTypeCreateEditDialog',

  components: {
    AddressForm,
    CreateEditDialog
  },

  props: {
    title: {
      type: String,
      default: 'Add Admin'
    },

    isOpen: Boolean,

    editingOrgType: {
      type: Object,
      required: false,
      default: null
    }
  },

  setup(props, { emit, root: { $FeathersVuex, $snackSuccess, $snackError } }) {
    const { IRSDesignation, OrganizationType } = $FeathersVuex.api
    const orgType = new OrganizationType(props.editingOrgType || {})

    const { items: irsDesignations, isPending } = useFind({
      model: IRSDesignation,
      params: { query: { selectable: true } }
    })

    const saveOrgType = async ({ save, reset }) => {
      try {
        await save()
        const message = 'Organization Type';
        $snackSuccess(message + (props.editingOrgType ? 'Saved' : 'Added'))
        emit('close')
      } catch (e) {
        $snackError('Error adding the organization type')
      } finally {
        reset()
      }
    }

    return {
      irsDesignations,
      isPending,
      orgType,

      saveOrgType
    }
  }
}
</script>

<style>

</style>